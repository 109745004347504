<template>
  <div className="animated">
    <i class="icon-menu mr-1"></i>Invoice List<hr />
    <b-row class="mb-3">
      <b-col cols="12" lg="3">
        <b-form-group
          description="Event to filter by. Leave as 'Event' to omit."
          label="Event"
          label-for="table-invoices-event"
          label-sr-only
        >
          <b-form-select id="table-invoices-event" v-model="queryFilter['items.registration.event']" :options="events" value-field="@id" text-field="title">
            <template v-slot:first>
              <option :value="null">Event</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-if="!setFromRoute" cols="12" lg="6">
        <b-row no-gutters>
          <b-col cols="12" lg="4">
            <b-form-group
              description="Date type to filter by."
              label="Date Start"
              label-for="table-invoices-date-type"
              class="mx-1"
              label-sr-only
            >
              <b-input-group prepend="Date">
                <b-form-select
                  id="table-invoices-date-type"
                  v-model="queryFilterDate.type"
                  :options="[{value:'datePaid',text:'Paid On'},{value:'dateCreated',text:'Added On'}]"
                ></b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="4">
            <b-form-group
              description="Minimum date. Leave blank to omit."
              label="Date Start"
              label-for="table-invoices-date-1"
              class="mx-1"
              label-sr-only
            >
              <b-input-group prepend="Start">
                <b-input type="date" id="table-invoices-date-1" v-model="queryFilterDate.after" /> 
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="4">
            <b-form-group
              description="Maximum date. Leave blank to omit."
              label="Date End"
              label-for="table-invoices-datePaid-2"
              class="mx-1"
              label-sr-only
            >
              <b-input-group prepend="End">
                <b-input type="date" id="table-invoices-datePaid-2" v-model="queryFilterDate.before" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-group
          description="Transaction ID to filter by. Leave blank to omit."
          label="Transaction ID"
          label-for="table-invoices-transactionId"
          label-sr-only
        >
          <b-input type="search" id="table-invoices-transactionId" v-model="queryFilter.transactionId" placeholder="Transaction ID" />
        </b-form-group>
      </b-col>
    </b-row>
    <v-server-table 
      class="bg-light p-2" 
      name="dataTableInvoiceList" 
      :columns="columns" 
      :url="url"
      :options="options" 
      :useVuex="true"
      :theme="theme" 
      id="table-invoices" 
      ref="table-invoices" 
      :preserveState="preserveState"
    >
      <!-- template slot="check" slot-scope="props">
        <b-form-checkbox
          v-model="actionIds[props.row.id]"
          value="1"
        ></b-form-checkbox>
      </template -->
      <template slot="items" slot-scope="props">
        {{ props.row.items.length }}
      </template>
      <template slot="member" slot-scope="props">
        {{ props.row.member.fullName }}
      </template>
      <template slot="dateCreated" slot-scope="props">
        <span v-if="props.row.dateCreated">{{ $moment(props.row.dateCreated).format('YYYY-MM-DD') }}</span>
        <div v-else>-</div>
      </template>
      <template slot="discountCode" slot-scope="props">
        <span v-if="props.row.discountCode">{{ props.row.discountCode.code }}</span>
        <div v-else> - </div>
      </template>
      <template slot="paymentMethod.paymentOption.label" slot-scope="props">
        <span v-if="props.row.paymentMethod">{{ props.row.paymentMethod.paymentOption.label }}</span>
        <div v-else>-</div>
      </template>
      <template slot="datePaid" slot-scope="props">
        <span v-if="props.row.datePaid">{{ $moment(props.row.datePaid).format('YYYY-MM-DD') }}</span>
        <div v-else>-</div>
      </template>
      <template slot="transactionId" slot-scope="props">
        <span v-if="props.row.transactionId">{{ props.row.transactionId }}</span>
        <div v-else>-</div>
      </template>
      <div slot="actions" slot-scope="props" class="text-center">
        <b-button-group>
          <b-btn size="sm" variant="info" :to="{ name: 'invoiceDetails', params: { iid: props.row.id }}">View</b-btn>
          <b-btn v-if="!props.row.items.length" size="sm" variant="danger" @click="onDeleteInvoice(props.row.id)">Delete</b-btn>
          <!--b-btn size="sm" variant="warning" :to="{ name: 'invoiceForm', params: { iid: props.row.id }}">Edit</b-btn -->
        </b-button-group>
      </div>
    </v-server-table>
    <download-excel
      class="btn btn-light mt-3 float-right"
      :fetch="doGetInvoices"
      :fields="Object.keys(options.headings).reduce((ret, key) => {
        ret[options.headings[key]] = key;
        return ret;
      }, {})"
      :name="'n-zone-invoices' + '.' + this.$moment().format('YYMMDDThhmm') + '.xls'"
      :title="'N Zone Invoices'"
      :worksheet="'N Zone Invoices'"
      type="xls"
    >
      <span :title="'Download Invoices (XLS)'"><i class="fa fa-download"></i> Download All</span>
    </download-excel>
  </div>
</template>

<script>
import Vue from 'vue'
import { HTTP } from '@/services/http-common'
import { mapActions, mapGetters } from 'vuex'
import { ServerTable/* , Event (matanya.gitbook.io/vue-tables-2-premium/events) */ } from 'vue-tables-2-premium'
Vue.use(ServerTable)
const DEFAULT_PER_PAGE = 30
const DEFAULT_QS_DATE_TYPE = 'dateCreated'
export default {
  name: 'Invoices',
  data () {
    return {
      perPage: DEFAULT_PER_PAGE,
      actionIds: [],
      columns: [/*'check', */
        'id',
        'member',
        'dateCreated',
        'items',
        'discountCode',
        'amountDue',
        'datePaid',
        'paymentMethod.paymentOption.label',
        'transactionId',
        'actions'
      ],
      options: {
        texts: {
          filterPlaceholder: "IID / Member"
        },
        perPage: DEFAULT_PER_PAGE,
        perPageValues: [30,60,90,250,500],
        async requestFunction (data) {
          return await HTTP.get(this.url, {
              params: data
          }).catch(function (e) {
              this.dispatch('error', e)
          }.bind(this))
        },
        requestAdapter (data) {
          let query = {
            page: data.page ? data.page : '1',
            itemsPerPage: data.limit ? data.limit : DEFAULT_PER_PAGE
          }
          if (data.query !== '') {
            query[(!isNaN(data.query) ? 'id' : 'member.fullName')] = data.query
          }
          if (data.orderBy) {
            let param = 'order[' + data.orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[' + DEFAULT_QS_DATE_TYPE + ']'] = 'desc'
          }
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        },
        headings: {
          // check: '',
          id: 'IID',
          member: 'Member',
          discountCode: 'Discount',
          dateCreated: 'Added On',
          amountDue: 'Total Due',
          datePaid: 'Paid On',
          'paymentMethod.paymentOption.label': 'Method',
          transactionId: 'Transaction ID',
          actions: 'Action'
        },
        sortable: ['id', 'discountCode', 'amountDue', 'dateCreated', 'datePaid', 'paymentMethod', 'transactionId'],
        filterable: ['id', 'member'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        },
        rowClassCallback: (row) => {
          return !row.transactionId ? 'bg-warning text-dark' : ''
        }
      },
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      queryFilterDate: {
        type: DEFAULT_QS_DATE_TYPE,
        after: null,
        before: null
      },
      queryFilter: {
        'items.registration.event': null,
        'dateCreated[after]': null,
        'dateCreated[before]': null,
        'datePaid[after]': null,
        'datePaid[before]': null,
        transactionId: null
      },
      // @XXX temporary hack - setDateFromRoute not setting queryFilter correctly
      setFromRoute: false
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'invoices',
      'events'
    ]),
    url () {
      this.setDateFromRoute()
      const queryFilter = { ...this.queryFilter }
      const params = {}
      for (const k in queryFilter) {
        if (queryFilter[k]) params[k] = queryFilter[k]
      }
      if (this.currentBusiness.id) {
        params.business = this.currentBusiness.id
      } else if (this.isAdmin && this.$route.query.bid) {
        params.business = this.$route.query.bid
      }
      const query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      return '/invoices?' + query
    }
  },
  created () {
    this.setEvents()
    // this.setDateFromRoute()
  },
  destroyed () {
    // this.$store.dispatch('resetEvents')
  },
  watch: {
    '$route.query' (val) {
      if (val.bid) {
        this.queryFilter.business = val.bid
      } else if (this.currentBusiness.id) {
        this.queryFilter.business = this.currentBusiness.id
      } else {
        this.queryFilter.business = null
      }
      this.setEvents()
    },
    'queryFilterDate.after' (val) {
      this.queryFilter[this.queryFilterDate.type + '[after]'] = val ? this.$moment(val).format('YYYY-MM-DD') : null
    },
    'queryFilterDate.before' (val) {
      this.queryFilter[this.queryFilterDate.type + '[before]'] = val ? this.$moment(val).format('YYYY-MM-DD') : null
    },
    'queryFilterDate.type' (val, oldVal) {
      this.queryFilter[val + '[after]'] = this.queryFilter[oldVal + '[after]']
      this.queryFilter[val + '[before]'] = this.queryFilter[oldVal + '[before]']
      this.queryFilter[oldVal + '[after]'] = null
      this.queryFilter[oldVal + '[before]'] = null
    }
  },
  methods: {
    ...mapActions([
      'getInvoices',
      'getEvents',
      'resetEvents',
      'deleteInvoice',
      'resetInvoices'
    ]),
    setEvents () {
      if (this.isAdmin && this.$route.query.bid) {
        this.getEvents({
          business: this.$route.query.bid,
          status: [1,2]
        })
      } else if (this.currentBusiness?.id) {
        this.getEvents({
          business: this.currentBusiness.id,
          status: [1,2]
        })
      } else {
        this.resetEvents()
      }
    },
    setDateFromRoute () {
      if (!this.$route.query) return
      let routeQuery = this.$route.query
      // get type in route query
      if (routeQuery.type) {
        this.queryFilterDate.type = routeQuery.type
      }
      // get for month & year in route query
      if (routeQuery.mo && routeQuery.yr) {
        let month  = routeQuery.yr + '-' + (routeQuery.mo > 9 ? routeQuery.mo : "0" + routeQuery.mo) + '-01'
        this.queryFilterDate.after = this.$moment(month).startOf('month').format('YYYY-MM-DD')
        this.queryFilterDate.before = this.$moment(month).endOf('month').format('YYYY-MM-DD')
        this.setFromRoute = true
      }
      this.queryFilter[this.queryFilterDate.type + '[after]'] = this.queryFilterDate.after
      this.queryFilter[this.queryFilterDate.type + '[before]'] = this.queryFilterDate.before
    },
    /**
     * gets invoices for export
     */
    async doGetInvoices () {
      await this.$store.dispatch('resetInvoices')
      const queryFilter = { ...this.queryFilter }
      let query = {
        pagination: false,
        business: this.isAdmin && this.$route.query.bid ? this.$route.query.bid : this.business.id
      }
      for (const k in queryFilter) {
        if (queryFilter[k]) query[k] = queryFilter[k]
      }
      const invoices = await this.$store.dispatch('getInvoices', query)
      return invoices.map(e => {
        e.dateCreated = this.$moment(e.dateCreated).format('MMM D, YYYY')
        e.datePaid = this.$moment(e.datePaid).format('MMM D, YYYY')
        e.member = e.member.fullName
        e.discountCode = e.discountCode ? e.discountCode.code : ''
        return e
      })
    },
    onDeleteInvoice (id) {
      this.$bvModal.msgBoxConfirm(`Permanenantly DELETE invoice #${id}?`, {
        title: 'Please Confirm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async confirm => {
        if (confirm) {
          try {
            await this.$store.dispatch('deleteInvoice', id)
            this.$refs['table-invoices'].refresh()
          } catch (err) {
            console.error(err)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
    }
  }
}
</script>
